import type { UseQueryOptions, UseQuerySharedOptions } from '@core/types'

export const useConsentContent = () => {
    const strapi = useStrapi()

    function getPrivacyPolicy(options: Pick<UseQuerySharedOptions, 'enabled'> & Pick<UseQueryOptions<any>, 'lazy' | 'server'> = {}) {
        const { enabled = true, lazy = true, server = false } = options

        const query = useQuery(async () => {
            const { data, error } = await strapi.GET('/consents/{type}', {
                params: {
                    path: {
                        type: 'privacy-policy',
                    },
                },
            })

            if (error) throw new Error('[useConsentContent] Failed to get privacy policy', {
                cause: error,
            })

            return data.data?.attributes
        }, {
            key: 'strapi:consent:privacy-policy',
            lazy,
            server,
            enabled,
        })

        return query
    }

    function getGeneralTerms(options: Pick<UseQuerySharedOptions, 'enabled'> & Pick<UseQueryOptions<any>, 'lazy' | 'server'> = {}) {
        const { enabled = true, lazy = true, server = false } = options

        const query = useQuery(async () => {
            const { data, error } = await strapi.GET('/consents/{type}', {
                params: {
                    path: {
                        type: 'general-terms',
                    },
                },
            })

            if (error) throw new Error('[useConsentContent] Failed to get general terms', {
                cause: error,
            })

            return data.data?.attributes
        }, {
            key: 'strapi:consent:general-terms',
            lazy,
            server,
            enabled,
        })

        return query
    }

    function getType(options: Pick<UseQuerySharedOptions, 'enabled'> & Pick<UseQueryOptions<any>, 'lazy' | 'server' | 'immediate'> & { type: MaybeRefOrGetter<string> }) {
        const { type, enabled = true, lazy = true, immediate = true, server = false } = options

        const query = useQuery(async () => {
            const { data, error } = await strapi.GET('/consents/{type}', {
                params: {
                    path: {
                        type: toValue(type),
                    },
                },
            })

            if (error) throw new Error(`[useConsentContent] Failed to get type: ${type}`, {
                cause: error,
            })

            return data.data?.attributes
        }, {
            key: () => `strapi:consent:${toValue(type)}`,
            lazy,
            server,
            enabled,
            immediate,
        })

        return query
    }

    return {
        getPrivacyPolicy,
        getGeneralTerms,
        getType,
    }
}
